// import { createStore } from 'redux'

// const initialState = {
//   sidebarShow: 'responsive',
//   userRole: null,
//   authToken: null,
//   userDetail: null
// }

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return {...state, ...rest }
//     default:
//       return state
//   }
// }

// const store = createStore(changeState)
// export default store

import { configureStore } from '@reduxjs/toolkit'
import intervalSlice from './store/reduxStore'

const store = configureStore({
  reducer: {
    interval: intervalSlice,
  },
})

export default store