import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    intervalId: 0,
}

export const intervalSlice = createSlice({
  name: 'interval',
  initialState,
  reducers: {
    update: (state, action) => {
      state.intervalId = action.payload
    }
  },
})

export const { update } = intervalSlice.actions

export default intervalSlice.reducer